import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 25 30 Z Z Z M 29 61 L 29 40 L 50 61 L 50 30 L 46 30 L 46 51 L 25 30 L 25 61 L 29 61"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
  // <StaticImage
  //   id="logo"
  //   src="../../images/lforlogo.png"
  //   formats={['AUTO', 'WEBP', 'AVIF', 'PNG']}
  //   alt="Headshot"
  // />
);

export default IconLogo;
